import type { BaseJsProps } from '@shared/node-v1.0.0';
import type { JsNodeDef } from '@shared/node-v1.0.0';
import { getPortDef } from '@shared/port-v1.0.0';
import getStore from './store';
import { validateFetchScheme } from './validtaion';
import type { FetchScheme } from './validtaion';
import type { Store } from './store';
import type { Nodes, NodeMultiSelection, NodeSingleSelection, NodeExpansion, MultiSelection } from '../component/Node';
import type { HistoryItem, ItemsHistory } from '../component/fetch';
import { unsubscribe } from '../component/handleSubscribe';
import initState from '@shared/init-state-v0.1.0';

export type Props = BaseJsProps & BaseProps & { store: Store };

export type BaseProps = {
	apiVersion: 'v2';
	fetchScheme?: FetchScheme;
	outputDbClasses?: string[];
	controlled: boolean;
	subscribe: boolean;
};

export type { Nodes, NodeMultiSelection, NodeSingleSelection, NodeExpansion, MultiSelection, HistoryItem, ItemsHistory };

export default {
	hashTag: '#pre-release',
	module: { dynamic: import('../component/useData') },
	inputs: [
		getPortDef({
			name: 'apiVersion',
			displayName: 'API',
			group: 'Version',
			default: 'v2',
			type: [{ label: 'v2', value: 'v2' }],
			visibleAt: 'editor',
		}),
		getPortDef({
			name: 'fetchScheme',
			displayName: 'Fetch scheme',
			group: 'Params',
			type: 'array',
			validate: (p: Props) => (p.fetchScheme ? validateFetchScheme(p) : true),
		}),
		getPortDef({
			name: 'controlled',
			displayName: 'Controlled',
			group: 'Params',
			type: 'boolean',
			default: false,
			visibleAt: 'editor',
		}),
		getPortDef({
			name: 'subscribe',
			displayName: 'Enable',
			group: 'Custom',
			customGroup: 'Subscribe',
			type: 'boolean',
			default: true,
			visibleAt: 'editor',
			dependsOn: (p: Props) => (p.controlled ? false : true),
		}),
		getPortDef({
			name: 'fetch',
			displayName: 'Fetch',
			group: 'Signals',
			type: 'signal',
			dependsOn: (p: Props) => (p.controlled ? true : false),
		}),
		getPortDef({
			name: 'outputDbClasses',
			displayName: 'Output DB classes',
			group: 'Custom',
			customGroup: 'Output DB classes',
			type: 'proplist',
			validate: (p: Props) => {
				if (R.dbClasses) {
					let notExistsDbClasses: string[] = [];
					p.outputDbClasses?.forEach((i) => {
						if (!R.dbClasses?.[i]) notExistsDbClasses.push(i);
					});
					if (notExistsDbClasses.length) return `There is no such DB classes as "${notExistsDbClasses.join('", "')}"`;
				}
				return true;
			},
		}),
		getPortDef({ name: 'resetSingleSelection', displayName: 'Reset single selection', group: 'Signals', type: 'signal' }),
		getPortDef({ name: 'resetMultiSelection', displayName: 'Reset multi selection', group: 'Signals', type: 'signal' }),
		getPortDef({ name: 'expandAll', displayName: 'Expand all', group: 'Signals', type: 'signal' }),
		getPortDef({ name: 'collapseAll', displayName: 'Collapse all', group: 'Signals', type: 'signal' }),
	],
	outputs: [
		getPortDef({ name: 'fetching', displayName: 'Fetching', group: 'States', type: 'boolean' }),
		getPortDef({ name: 'fetched', displayName: 'Fetched', group: 'Signals', type: 'signal' }),
		getPortDef({ name: 'data', displayName: 'Data', group: 'Data', type: 'object' }),
		getPortDef({ name: 'rootId', displayName: 'Root node id', group: 'Data', type: 'string' }),
		getPortDef({ name: 'rootNode', displayName: 'Root node', group: 'Data', type: 'object' }),
		getPortDef({ name: 'schemes', displayName: 'Schemes', group: 'Data', type: 'array' }),
		getPortDef({ name: 'singleSelectionChanged', displayName: 'Single selection changed', group: 'Signals', type: 'signal' }),
		getPortDef({ name: 'multiSelectionChanged', displayName: 'Multi selection changed', group: 'Signals', type: 'signal' }),
		getPortDef({ name: 'expansionChanged', displayName: 'Expansion changed', group: 'Signals', type: 'signal' }),
	],
	transform: (p: Props, portDefs) => {
		// Пересоздание outputDbClasses
		const dbClasses = p.outputDbClasses;
		portDefs.outputs = portDefs.outputs.filter((i) => !i.group?.includes('Data:'));
		if (dbClasses)
			dbClasses.map((dbClass) => {
				portDefs.outputs.push(
					getPortDef({
						name: `${dbClass}Items`,
						group: 'Custom',
						customGroup: `Data: ${dbClass}`,
						type: 'array',
						displayName: `${dbClass} Items`,
					})
				);
				portDefs.outputs.push(
					getPortDef({
						name: `${dbClass}Fetched`,
						group: 'Custom',
						customGroup: `Data: ${dbClass}`,
						type: 'number',
						displayName: `${dbClass} Fetched`,
					})
				);
				portDefs.outputs.push(
					getPortDef({
						name: `${dbClass}Total`,
						group: 'Custom',
						customGroup: `Data: ${dbClass}`,
						type: 'number',
						displayName: `${dbClass} Total`,
					})
				);
				portDefs.outputs.push(
					getPortDef({
						name: `${dbClass}Aggregations`,
						group: 'Custom',
						customGroup: `Data: ${dbClass}`,
						type: 'object',
						displayName: `${dbClass} Aggregations`,
					})
				);
			});
	},
	triggerOnInputs: () => ['apiVersion', 'fetchScheme', 'controlled', 'subscribe'],
	initialize: async (p: Props, noodlNode) => {
		await initState('initialized');
		p.store = getStore(p);

		// Отпишемся, когда родитель отмонтировался. Родитель может быть страницей, в таком случае пропустим.
		if (noodlNode.nodeScope.componentOwner.parent?.innerReactComponentRef)
			noodlNode.nodeScope.componentOwner.parent.innerReactComponentRef.componentWillUnmount = () => unsubscribe(p);
		// Отпишемся, когда удален.
		noodlNode._onNodeDeleted = () => unsubscribe(p);
	},
	getInspectInfo: (p: Props) => [
		{ type: 'text', value: `API ${p.apiVersion}` },
		{ type: 'text', value: `=== Scheme ===` },
		{ type: 'value', value: p.fetchScheme },
	],
	disableCustomProps: true,
} satisfies JsNodeDef;
